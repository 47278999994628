import React from 'react'

import Layout from '../components/Layout'
import Footer from '../components/Footer'
import styled from 'styled-components'
import { themes } from '../components/styles/ColorStyles'
import { Fade } from '../components/styles/Animation'
import TimelineItem from '../components/TimelineItem'

const Hold = styled.div`
  margin: 140px 0px 24px 0px;
`

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`

const Line = styled.div`
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 434px;
    background-color: ${themes.light.neutral4};
    top: 160px;
    bottom: 0;
    left: 276px;
    margin-left: -3px;
  }

  @media (max-width: 800px) {
    &:after {
      left: 78px;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <Hold>
      <Fade delay="0.2">
        <Line></Line>
      </Fade>
      <TimelineWrapper>
        <TimelineItem
          dateTitle="1993."
          word1Title="Born. Raised in"
          linkTitle="Boxborough, MA"
          word2Title="#978."
          target="https://en.wikipedia.org/wiki/Boxborough,_Massachusetts"
          delay="0.1"
        />
        <TimelineItem
          dateTitle="2003."
          word1Title="Saw"
          linkTitle="Shaquille O'Neal"
          word2Title="in a restaurant."
          target="https://en.wikipedia.org/wiki/Shaquille_O%27Neal"
          delay="0.10"
        />
        <TimelineItem
          dateTitle="2008."
          word1Title="Pirated Photoshop for the first time."
          delay="0.11"
        />
        <TimelineItem
          dateTitle="2011."
          word1Title="Enrolled at"
          linkTitle="UConn"
          word2Title="go Huskies."
          target="https://uconn.edu/"
          delay="0.12"
        />
        <TimelineItem
          dateTitle="2015."
          word1Title="Graduated."
          linkTitle="Digital Media &amp; Design"
          word2Title="degree."
          target="https://dmd.uconn.edu/"
          delay="0.13"
        />
        <TimelineItem
          dateTitle="2016."
          word1Title="Started at"
          linkTitle="Priceline"
          word2Title="&amp; moved to NYC."
          target="https://priceline.com/"
          delay="0.14"
        />
        <TimelineItem
          dateTitle="2017."
          word1Title="Went to Japan twice."
          delay="0.15"
        />
        <TimelineItem
          dateTitle="2018."
          word1Title="Went to"
          linkTitle="Fiverr."
          target="https://fiverr.com/"
          word2Title=""
          overflow="Also went to Japan twice again."
          delay="0.16"
        />
        <TimelineItem
          dateTitle="2019."
          word1Title="Moved to"
          linkTitle="Stash."
          target="https://stash.com/"
          delay="0.17"
        />
        <TimelineItem
          dateTitle="2020."
          word1Title="Self explanatory."
          delay="0.18"
        />
        <TimelineItem
          dateTitle="2021."
          word1Title="Joined"
          linkTitle="Peloton."
          target="https://onepeloton.com/"
          delay="0.19"
        />
        <TimelineItem
          dateTitle="2022."
          word1Title="Currently at"
          linkTitle="Meta Reality Labs."
          target="https://about.facebook.com/realitylabs/"
          delay="0.2"
        />
        <TimelineItem
          dateTitle="2023."
          word1Title="???"
          linkTitle=""
          target=""
          delay="0.2"
        />
      </TimelineWrapper>
      <Footer
        text1="I made this website."
        emoji="🤙"
        text2="My email is sqott@sqott.design"
        delay="0.5"
      />
    </Hold>
  </Layout>
)

export default IndexPage
