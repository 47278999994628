import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'
import { fontWeight, Body16 } from './styles/TextStyles'
import TimelineLink from './TimelineLink'
import TimelineIcon from './TimelineIcon'
import { Slide } from '../components/styles/Animation'
import Link from 'gatsby-link'

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 22px;
  margin-bottom: 16px;
  @media (max-width: 800px) {
  }
`

const DateWrap = styled.div`
  width: 46px;
  margin-right: 16px;
  position: static;
  @media (max-width: 800px) {
    margin-right: 4px;
    width: 36px;
  }
`

const Date = styled(Body16)`
  color: ${themes.light.neutral9};
  font-weight: ${fontWeight.regular};

  @media (max-width: 800px) {
    font-size: 12px;
    margin-right: 8px;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  @media (max-width: 800px) {
    align-items: center;
  }
`

const Word = styled(Body16)`
  color: ${themes.light.neutral7};
  font-weight: ${fontWeight.regular};
  margin-right: 8px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const Overflow = styled(Body16)`
  color: ${themes.light.neutral7};
  font-weight: ${fontWeight.regular};
  margin-right: 8px;

  @media (max-width: 946px) {
    display: none;
  }
`

const TimelineItem = (props) => (
  <Slide delay={props.delay}>
    <Link href={props.target} target="_blank" rel="noreferrer">
    <Wrap>
      <DateWrap>
        <Date>{props.dateTitle}</Date>
      </DateWrap>
      <TimelineIcon></TimelineIcon>
      <Description>
        <Word>{props.word1Title}</Word>
        <TimelineLink linkTitle={props.linkTitle}></TimelineLink>
        <Word>{props.word2Title ? props.word2Title : null}</Word>
        <Overflow>{props.overflow}</Overflow>
      </Description>
    </Wrap>
    </Link>
  </Slide>
)

export default TimelineItem
