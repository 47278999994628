import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'


const Wrap = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  align-items: center;
  transition: 400ms;
  margin-right: 16px;

  @media (max-width: 800px) {
    margin-right: 4px;
  }
`

const Dot = styled.div`
    width: 12px;
    height: 12px;
    border: 2px solid ${themes.light.neutral4};
    background-color: ${themes.light.neutral2};
    box-sizing: border-box;
    border-style: solid;
    box-shadow: 0px 0px 0px 8px ${themes.light.neutral2};
    border-radius: 4px;
    transform: rotate(45deg);

`

const TimelineIcon = (props) => (
  
    <Wrap>
      <Dot>
      </Dot>
    </Wrap>

)

export default TimelineIcon
