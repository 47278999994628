import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'
import { fontWeight, Body16 } from './styles/TextStyles'
import Link from 'gatsby-link'

const Title = styled(Body16)`
  color: ${themes.light.neutral9};
  font-weight: ${fontWeight.bold};
  margin-right: 8px;
  text-decoration: none;
  display: inline-block;

  &:hover {
    cursor: pointer;
    &:after {
      background: ${themes.light.neutral9};
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    background: ${themes.light.neutral4};
    transition: 350ms;
  }

  @media (max-width: 800px) {
    font-size: 12px;
    margin-right: 4px;
  }
`

const TimelineLink = (props) => (
  <Link href={props.link} target="_blank" rel="noreferrer">
    <Title>{props.linkTitle}</Title>
  </Link>
)

export default TimelineLink
