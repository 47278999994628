import React from 'react'
import styled from 'styled-components'
import { fontWeight, Body16 } from './styles/TextStyles'
import { themes } from './styles/ColorStyles'
import { Slide } from './styles/Animation'

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 12px 24px 0px;
  width: 100%;
  background-color: ${themes.light.neutral2};

`
const TextWrap = styled.div`
  display: flex;
  justify-content: left;
  padding-left: 200px;
  align-items: center;

  &:hover .wig {
    animation-play-state: running;
  }

  @media (max-width: 800px) {
    padding-left: 24px;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-60deg);
    }
  }
`

const FooterText = styled(Body16)`
  color: ${themes.light.neutral7};
  text-align: center;
  font-weight: ${fontWeight.regular};

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const Emoji = styled(Body16)`
  animation: wiggle 0.2s alternate infinite;
  animation-play-state: paused;
  margin-right: 16px;
  margin-left: 8px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const Footer = (props) => (
  <FooterContainer>
    <Slide delay={props.delay}>
      <TextWrap>
        <FooterText>{props.text1}</FooterText>
        <Emoji className="wig">{props.emoji}</Emoji>
        <FooterText>{props.text2}</FooterText>
      </TextWrap>
    </Slide>
  </FooterContainer>
)

export default Footer
